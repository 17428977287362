import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { ModalDialogPrimitiveContent, ModalDialogPrimitiveOverlay } from '@twilio-paste/modal-dialog-primitive';
import Changelog from '@twilio-paste/modal-dialog-primitive/CHANGELOG.md';
import { Anchor } from '@twilio-paste/anchor';
import { Box } from '@twilio-paste/box';
import { SidebarCategoryRoutes } from '../../../constants';
import { Callout, CalloutTitle, CalloutText } from '../../../components/callout';
import { DoDont, Do, Dont } from '../../../components/DoDont';
import { ModalDialogPrimitiveExample } from '../../../component-examples/ModalDialogPrimitiveExample.tsx';
export const pageQuery = graphql`
  {
    allPastePrimitive(filter: {name: {eq: "@twilio-paste/modal-dialog-primitive"}}) {
      edges {
        node {
          name
          description
          status
          version
        }
      }
    }
    mdx(fields: {slug: {eq: "/primitives/modal-dialog-primitive/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentHeader = makeShortcode("ComponentHeader");
const PageAside = makeShortcode("PageAside");
const ChangelogRevealer = makeShortcode("ChangelogRevealer");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <ComponentHeader name="Modal Dialog Primitive" categoryRoute={SidebarCategoryRoutes.PRIMITIVES} githubUrl="https://github.com/twilio-labs/paste/tree/main/packages/paste-core/primitives/modal-dialog" storybookUrl="/?path=/story/primitives-modaldialog--custom-overlay-and-content" data={props.data.allPastePrimitive.edges} mdxType="ComponentHeader" />
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h2>{`Guidelines`}</h2>
        <h3>{`About the Modal Dialog Primitive`}</h3>
        <p>{`The modal dialog primitive is an unstyled and barebones version of a `}<a parentName="p" {...{
            "href": "https://www.w3.org/TR/wai-aria-practices/#dialog_modal"
          }}>{`Modal dialog`}</a>{`.
It handles the implementation details around accessibility and provides a robust API to
build upon. For example, our `}<a parentName="p" {...{
            "href": "../components/modal"
          }}>{`Modal component`}</a>{` is built on top of this primitive.
If you find that our designed Modal component can’t work for your particular use case,
we suggest falling back to this component to roll your own solution. We encourage
using this code as a basis for all modal dialogs in order to avoid code fragmentation
and accessibility issues in our products.`}</p>
        <Callout mdxType="Callout">
  <CalloutTitle as="h4" mdxType="CalloutTitle">Looking for Paste's styled Modal?</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    Only use this primitive if you have a bespoke modal design. For most Twilio interfaces, we recommend using the{' '}
    <Anchor href="/components/modal" mdxType="Anchor">Paste Modal component</Anchor>.
  </CalloutText>
        </Callout>
        <h2>{`Usage Guide`}</h2>
        <p>{`This package is a wrapper around `}<a parentName="p" {...{
            "href": "https://reacttraining.com/reach-ui/dialog"
          }}><inlineCode parentName="a">{`@reach/dialog`}</inlineCode></a>{`. If
you’re wondering why we wrapped that package into our own, we reasoned that it would be best
for our consumers’ developer experience. With reasons such as:`}</p>
        <ul>
          <li parentName="ul">{`We can control which APIs we expose and how to expose them. For example, in this package we rename
and export only some of the source package's exports.`}</li>
          <li parentName="ul">{`If we want to migrate the underlying nuts and bolts in the future, Twilio products that
depend on this primitive would need to replace all occurrences of `}<inlineCode parentName="li">{`import … from ‘@reach/dialog’`}</inlineCode>{`
to `}<inlineCode parentName="li">{`import … from ‘@some-new/package’`}</inlineCode>{`. By wrapping it in `}<inlineCode parentName="li">{`@twilio-paste/modal-dialog-primitive`}</inlineCode>{`,
this refactor can be avoided. The only change would be a version bump in the package.json file.`}</li>
          <li parentName="ul">{`We can more strictly enforce semver and backwards compatibility than some of our dependencies.`}</li>
          <li parentName="ul">{`We can control when to provide an update and which versions we allow, to help reduce
potential bugs our consumers may face.`}</li>
        </ul>
        <h4>{`Installation`}</h4>
        <p>{`This package is available individually or as part of `}<inlineCode parentName="p">{`@twilio-paste/core`}</inlineCode>{`.`}</p>
        <pre><code parentName="pre" {...{}}>{`yarn add @twilio-paste/modal-dialog-primitive - or - yarn add @twilio-paste/core
`}</code></pre>
        <h4>{`Usage Example`}</h4>
        <Box paddingBottom="space80" mdxType="Box">
  <ModalDialogPrimitiveExample mdxType="ModalDialogPrimitiveExample" />
        </Box>
        <pre><code parentName="pre" {...{}}>{`import * as React from 'react';
import {styled} from '@twilio-paste/styling-library';
import {Text} from '@twilio-paste/text';
import {Button} from '@twilio-paste/button';
import {ModalDialogPrimitiveOverlay, ModalDialogPrimitiveContent} from '@twilio-paste/modal-dialog-primitive';

const StyledModalDialogOverlay = styled(ModalDialogPrimitiveOverlay)({
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'rgba(0, 0, 0, 0.7)',
});

const StyledModalDialogContent = styled(ModalDialogPrimitiveContent)({
  width: '100%',
  maxWidth: '560px',
  maxHeight: 'calc(100% - 60px)',
  background: '#f4f5f6',
  borderRadius: '5px',
  padding: '20px',
});

interface BasicModalDialogProps {
  isOpen: boolean;
  handleClose: () => void;
}

const BasicModalDialog: React.FC<BasicModalDialogProps> = ({isOpen, handleClose}) => {
  const inputRef = React.useRef();

  return (
    <StyledModalDialogOverlay
      isOpen={isOpen}
      onDismiss={handleClose}
      allowPinchZoom={true}
      initialFocusRef={inputRef}
    >
      <StyledModalDialogContent>
        <input type="text" value="first" />
        <br />
        <input ref={inputRef} type="text" value="second (initial focused)" />
        <Text as="p" color="colorText">
          Roll your own dialog!
        </Text>
      </StyledModalDialogContent>
    </StyledModalDialogOverlay>
  );
};

export const ModalActivator: React.FC = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const handleOpen = (): void => setIsOpen(true);
  const handleClose = (): void => setIsOpen(false);

  return (
    <div>
      <Button variant="primary" onClick={handleOpen}>
        Open Sample Modal
      </Button>
      <BasicModalDialog isOpen={isOpen} handleClose={handleClose} />
    </div>
  );
};
`}</code></pre>
        <h3>{`API`}</h3>
        <Callout mdxType="Callout">
  <CalloutText mdxType="CalloutText">
    Much of the following is copied directly from{' '}
    <Anchor href="https://reacttraining.com/reach-ui/dialog/" mdxType="Anchor">reach-ui's docs</Anchor>. Because we may update at a
    different cadence, we're duplicating the docs here to prevent inconsistent behaviors.
  </CalloutText>
        </Callout>
        <h4>{`ModalDialogPrimitiveOverlay Props`}</h4>
        <p>{`All the regular HTML attributes (`}<inlineCode parentName="p">{`role`}</inlineCode>{`, `}<inlineCode parentName="p">{`aria-*`}</inlineCode>{`, `}<inlineCode parentName="p">{`type`}</inlineCode>{`, and so on) including the following custom props:`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`isOpen`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`bool`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`false`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`allowPinchZoom?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`bool`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`false`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`onDismiss?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`(event) => void`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`noop`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`initialFocusRef?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`ref`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`children`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`node`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
          </tbody>
        </table>
        <h5><inlineCode parentName="h5">{`isOpen`}</inlineCode>{` prop`}</h5>
        <p>{`Controls whether the dialog is open or not.`}</p>
        <pre><code parentName="pre" {...{}}>{`<ModalDialogPrimitiveOverlay isOpen={true}>
  <p>I will be open</p>
</ModalDialogPrimitiveOverlay>

<ModalDialogPrimitiveOverlay isOpen={false}>
  <p>I will be closed</p>
</ModalDialogPrimitiveOverlay>
`}</code></pre>
        <h5><inlineCode parentName="h5">{`allowPinchZoom`}</inlineCode>{` prop`}</h5>
        <p>{`Controls whether the dialog should allow zoom/pinch gestures on iOS devices.`}</p>
        <h5><inlineCode parentName="h5">{`onDismiss`}</inlineCode>{` prop`}</h5>
        <p>{`This function is called whenever the user hits "Escape" or clicks outside the dialog.
It's important to close the dialog when onDismiss is fired, as seen in all the demos on this page.`}</p>
        <p>{`The only time you `}<em parentName="p">{`shouldn't`}</em>{` close the dialog onDismiss is when the dialog requires a
choice and none of them are "cancel". For example, perhaps two records need to be merged
and the user needs to pick the surviving record. Neither choice is less destructive than
the other, in these cases you may want to alert the user they need to a make a choice
onDismiss instead of closing the dialog.`}</p>
        <pre><code parentName="pre" {...{}}>{`function Example(props) {
  const [showDialog, setShowDialog] = React.useState(false);
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);

  return (
    <div>
      <button onClick={open}>Show Dialog</button>
      <ModalDialogPrimitiveOverlay isOpen={showDialog} onDismiss={close}>
        <ModalDialogPrimitiveContent>
          <Text as="p">
            It is your job to close this with state when the user clicks outside
            or presses escape.
          </Text>
          <button onClick={close}>Okay</button>
        </ModalDialogPrimitiveContent>
      </ModalDialogPrimitiveOverlay>
    </div>
  );
}
`}</code></pre>
        <pre><code parentName="pre" {...{}}>{`function Example(props) {
  const [showDialog, setShowDialog] = React.useState(false);
  const [showWarning, setShowWarning] = React.useState(false);
  const open = () => {
    setShowDialog(true);
    setShowWarning(false);
  };
  const close = () => setShowDialog(false);
  const dismiss = () => setShowWarning(true);

  return (
    <div>
      <button onClick={open}>Show Dialog</button>
      <ModalDialogPrimitiveOverlay isOpen={showDialog} onDismiss={close}>
        <ModalDialogPrimitiveContent>
          {showWarning && (
            <p style={{ color: "red" }}>You must make a choice, sorry :(</p>
          )}
          <p>Which router should survive the merge?</p>
          <button onClick={close}>React Router</button>{" "}
          <button onClick={close}>@reach/router</button>
        </ModalDialogPrimitiveContent>
      </ModalDialogPrimitiveOverlay>
    </div>
  );
}
`}</code></pre>
        <h5><inlineCode parentName="h5">{`initialFocusRef`}</inlineCode>{` prop`}</h5>
        <p>{`By default the first focusable element will receive focus when the dialog opens but you
can provide a ref to focus instead.`}</p>
        <pre><code parentName="pre" {...{}}>{`function Example(props) {
  const [showDialog, setShowDialog] = React.useState(false);
  const buttonRef = React.useRef();
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);

  return (
    <div>
      <button onClick={open}>Show Dialog</button>
      {showDialog && (
        <ModalDialogPrimitiveOverlay initialFocusRef={buttonRef} onDismiss={close}>
          <ModalDialogPrimitiveContent>
            <p>Pass the button ref to DialogOverlay and the button.</p>
            <button onClick={close}>Not me</button>
            <button
              ref={buttonRef}
              onClick={close}
            >
              Got me!
            </button>
          </ModalDialogPrimitiveContent>
        </ModalDialogPrimitiveOverlay>
      )}
    </div>
  );
}
`}</code></pre>
        <h4>{`ModalDialogPrimitiveContent Props`}</h4>
        <p>{`All the regular HTML attributes (`}<inlineCode parentName="p">{`role`}</inlineCode>{`, `}<inlineCode parentName="p">{`aria-*`}</inlineCode>{`, `}<inlineCode parentName="p">{`type`}</inlineCode>{`, and so on) including the following custom props:`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`children`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`node`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
          </tbody>
        </table>
        <ChangelogRevealer mdxType="ChangelogRevealer">
  <Changelog mdxType="Changelog" />
        </ChangelogRevealer>
      </content>
    </contentwrapper>
```

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      